import * as React from "react"
import { ImgDestra, ImgSinistra, ImgCentro, ImgLogo, Img, Citazione, IndiceLuoghi, PaginaTesto, ChiSono, ChiSiamo, A, FbPage } from "../../../components/componentiPagine"

export default function Render() {
	return <PaginaTesto lang="it" name="londra1839">
<h1>Il periodo londinese di &quot;Luigi Mariotti&quot;</h1>
<p>A bordo del battello che lo conduce in Inghilterra, il Saint James, Antonio fa la conoscenza della romanziera americana Catharine Sedgewick la quale, una volta sbarcati a Londra, lo introduce nella buona società e negli ambienti della cultura londinesi.</p>
<ImgCentro nome="londra.PNG" alt="londra" didascalia="Londra"/>
<p>Inizialmente incontra difficoltà economiche e cerca impiego come professore di italiano attraverso inserzioni sui giornali.<br />
Un giorno incontra per caso Giuseppe Mazzini, in quel momento anche lui esule a Londra, e fa la conoscenza del pedagogista Enrico Mayer.</p>
<ImgSinistra nome="giornali.PNG" alt="Riviste" />
Quest’ultimo offre ad Antonio la possibilità di tradurre in inglese alcuni suoi articoli di carattere educativo e gli offre un lauto compenso, inoltre gli presenta alcuni suoi celebri amici, tra cui John Stuart Mill e Lord Byron. 
<p>Grazie all’incontro con la scrittrice e traduttrice Sarah Austin, inizia a collaborare con diverse riviste, quali il Metropolitan Magazine, la Foreign and Quarterly Review e la British and Foreign Review.</p>
<p>Nel 1841 dà alle stampe, firmandosi come Luigi Mariotti, un libro sulla situazione storico-politica dell’Italia vista da un esule dal titolo Italy, General Views of its History and Literature in Reference to its Present State (ripubblicato lo stesso anno con il titolo “Italy, Past and Present).</p>
<ImgCentro href="https://books.google.it/books?id=xtspAAAAYAAJ&pg=PA1&lpg=PA1&dq=italy,+general+views+of+its+history+and+literature+gallenga&source=bl&ots=LmrxHagFRy&sig=ACfU3U0P_ky682HIemFAuQvZ23A3aU0cEA&hl=it&sa=X&ved=2ahUKEwjws7Pa1-DyAhUIhP0HHchYCTsQ6AF6BAgbEAM#v=onepage&q=italy%2C%20general%20views%20of%20its%20history%20and%20literature%20gallenga&f=false" nome="mariotti.PNG" alt="volume"/>
<p>Il libro riceve le lodi di intellettuali del calibro di Edward Bulwer-Lytton, Lady Sidney Morgan, Lady Blessington e Benjamin Disraeli, e il successo contribuisce ad aprire ad Antonio le porte di molti salotti londinesi.</p>
<ImgDestra nome="divanetto.PNG" alt="stile vittoriano"/>
<h2>Link per approfondire</h2>
<p><A href="https://www.treccani.it/enciclopedia/disraeli-benjamin-conte-di-beaconsfield/">“Benjamin Disraeli”</A></p>
<p><A href="https://www.womenhistoryblog.com/2012/01/catharine-maria-sedgwick.html">“Catharine Sedgewick”</A></p>
<p><A href="https://www.treccani.it/enciclopedia/lytton-edward-george-conte-bulwer-lytton-primo-barone-lytton_%28Enciclopedia-Italiana%29/">“Edward Bulwer-Lytton”</A></p>
<p><A href="https://www.treccani.it/enciclopedia/enrico-mayer_%28Dizionario-Biografico%29/">“Enrico Mayer”</A></p>
<p><A href="https://books.google.it/books?id=xtspAAAAYAAJ&pg=PA1&lpg=PA1&dq=italy,+general+views+of+its+history+and+literature+gallenga&source=bl&ots=LmrxHagFRy&sig=ACfU3U0P_ky682HIemFAuQvZ23A3aU0cEA&hl=it&sa=X&ved=2ahUKEwjws7Pa1-DyAhUIhP0HHchYCTsQ6AF6BAgbEAM#v=onepage&q=italy%2C%20general%20views%20of%20its%20history%20and%20literature%20gallenga&f=false">“Italy, General Views of its History and Literature in Reference to its Present State”</A></p>
<p><A href="https://it.wikipedia.org/wiki/Marguerite_di_Blessington">“Lady Blessington”</A></p>
<p><A href="https://www.treccani.it/enciclopedia/lady-sidney-morgan_%28Enciclopedia-Italiana%29/">“Lady Sidney Morgan”</A></p>
<p><A href="https://en.wikipedia.org/wiki/Sarah_Austin_(translator)">“Sarah Austin”</A></p>

	</PaginaTesto>
}